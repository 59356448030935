import React, { useRef, useState } from 'react'
import { useMutation, gql, useQuery } from '@apollo/client'
import { validateFunc } from '../../constraints/constraints'
import { withTranslation } from 'react-i18next'
import { createBanner, editBanner, getBanners, restaurants } from '../../apollo'
import useStyles from './styles'
import useGlobalStyles from '../../utils/globalStyles'
import {
  Box,
  Typography,
  Input,
  Button,
  Alert,
  Grid,
  useTheme,
  Select,
  MenuItem
} from '@mui/material'
import ConfigurableValues from '../../config/constants'

const CREATE_BANNER = gql`
  ${createBanner}
`
const EDIT_BANNER = gql`
  ${editBanner}
`
const GET_BANNERS = gql`
  ${getBanners}
`
const GET_RESTAURANTS = gql`
  ${restaurants}
`

function Banner(props) {
  const formRef = useRef()
  const { CLOUDINARY_UPLOAD_URL, CLOUDINARY_FOOD } = ConfigurableValues()

  const mutation = props.banner ? EDIT_BANNER : CREATE_BANNER

  const [mainError, mainErrorSetter] = useState('')
  const [success, successSetter] = useState('')
  const [file, setFile] = useState(props.banner ? props.banner.file : '')
  const [fileLoading, setFileLoading] = useState(false)

  const restId = props?.banner?.parameters && JSON.parse(props?.banner?.parameters)

  const [data, setData] = useState({
    title: props.banner ? props.banner.title : '',
    description: props.banner ? props.banner.description : '',
    action: props.banner ? props.banner.action : 'navigate',
    screen: props.banner ? props.banner.screen : 'Restaurant',
    restaurantId: props.banner && restId ? restId[0]?.value : '',
  })
  const [errors, setErrors] = useState({
    title: '',
    description: '',
    action: '',
    screen: '',
    restaurantId: ''
  })

  const [parameter, setParameter] = useState(props.banner ? JSON.parse(props.banner.parameters) : [
    {
      key: 'restaurant',
      value: ''
    }
  ])

  const {
    data: restaurants,
  } = useQuery(GET_RESTAURANTS, { fetchPolicy: 'network-only' })

  const filterImage = event => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i)
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
    return images.length ? images[0] : undefined
  }

  const imageToBase64 = imgUrl => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      setFile(fileReader.result)
    }
    fileReader.readAsDataURL(imgUrl)
  }

  const selectImage = (event, state) => {
    const result = filterImage(event)
    if (result) imageToBase64(result)
  }

  const onCompleted = data => {
    const message = props.banner ? t('BannerUpdated') : t('BannerAdded')
    successSetter(message)
    mainErrorSetter('')
    if (!props.banner) clearFields()
  }
  const onError = error => {
    let message = ''
    try {
      message = error.graphQLErrors[0].message
    } catch (err) {
      message = t('ActionFailedTryAgain')
    }
    successSetter('')
    mainErrorSetter(message)
  }
  const [mutate, { loading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_BANNERS }],
    onError,
    onCompleted
  })

  const onSubmitValidaiton = () => {
    const titleError = validateFunc(
      { title: data.title },
      'title'
    )
    const descriptionError = validateFunc(
      { description: data.description },
      'description'
    )
    const actionError = validateFunc(
      { action: data.action },
      'action'
    )
    const screenError = validateFunc(
      { screen: data.screen },
      'screen'
    )
    const restaurantIdError = validateFunc(
      { restaurantId: data.restaurantId },
      'restaurantId'
    )
    setErrors({
      title: titleError,
      description: descriptionError,
      action: actionError,
      screen: screenError,
      restaurantId: restaurantIdError
    })

    return !titleError?.title && !descriptionError?.description && !actionError?.action && !screenError?.screen && !restaurantIdError?.restaurantId
  }

  const clearFields = () => {
    setErrors({
      title: '',
      description: '',
      action: '',
      screen: ''
    })
    setData({
      title: '',
      description: '',
      action: '',
      screen: ''
    })
    setParameter([
      {
        key: '',
        value: ''
      }
    ])
    setFile('')
  }

  const onDataChange = (name, value) => {
    setErrors((prev)=>({...prev, [name]: null}))
    setData((prev)=>({...prev, [name]: value}))
  }

  const uploadImageToCloudinary = async() => {
    if (file === '') return file
    if (props.banner && props.banner.file === file) return file

    setFileLoading(true)
    const apiUrl = CLOUDINARY_UPLOAD_URL
    const data = {
      file: file,
      upload_preset: CLOUDINARY_FOOD
    }
    try {
      const result = await fetch(apiUrl, {
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST'
      })
      const imageData = await result.json()
      return imageData.secure_url
    } catch (e) {
      console.log('Image upload error => ', e)
    } finally{
      setFileLoading(false)
    }
  }

  const { t } = props
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  return (
    <Box container className={classes.container}>
      <Box className={classes.flexRow}>
        <Box
          item
          className={props.banner ? classes.headingBlack : classes.heading}>
          <Typography
            variant="h6"
            className={props.banner ? classes.textWhite : classes.text}>
            {props.banner ? t('EditBanner') : t('AddBanner')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.form}>
        <form ref={formRef}>
          <Box className={globalClasses.flexRow}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={5}>
                <Typography className={classes.labelText}>
                  {t('Title')}
                </Typography>
                <Input
                  style={{ marginTop: -1 }}
                  placeholder={t('Title')}
                  type="text"
                  defaultValue={data.title}
                  onChange={(e)=>onDataChange('title', e.target.value)}
                  disableUnderline
                  className={[
                    globalClasses.input,
                    errors.title ? globalClasses.inputError : "",
                    data.title && !errors.title && globalClasses.inputSuccess
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography className={classes.labelText}>
                  {t('Description')}
                </Typography>
                <Input
                  style={{ marginTop: -1 }}
                  placeholder={t('Description')}
                  type="text"
                  defaultValue={data.description}
                  onChange={(e)=>onDataChange('description', e.target.value)}
                  disableUnderline
                  className={[
                    globalClasses.input,
                    errors.description
                      ? globalClasses.inputError
                      : "",
                      data.description && !errors.description && globalClasses.inputSuccess
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
          <Grid item xs={12}>
                <Typography className={classes.labelText}>
                  {t('Action')}
                </Typography>
                {restaurants?.restaurants?.length > 0  && (
                <Select
                  style={{ marginTop: -1 }}
                  defaultValue={data.restaurantId}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  value={data.restaurantId}
                  placeholder='Select action'
                  onChange={(e)=>onDataChange('restaurantId', e.target.value)}
                  className={[
                    globalClasses.input,
                    errors.restaurantId
                      ? globalClasses.inputError
                      : "",
                      data.restaurantId && !errors.restaurantId && globalClasses.inputSuccess
                  ]}>
                    {
                      restaurants?.restaurants?.map((item, index)=>(
                        <MenuItem
                          style={{ color: 'black', textTransform: 'capitalize' }}
                          value={item?._id}
                          key={item?._id}
                          >
                          {item?.name}
                        </MenuItem>
                      ))}
                </Select>

                )}
              </Grid>

          </Box>
          <Box>

          </Box>
          <Box
            mt={3}
            style={{ alignItems: 'center' }}
            className={globalClasses.flex}>
            <img
              className={classes.image}
              alt="..."
              src={
                file ||
                'https://enatega.com/wp-content/uploads/2023/11/man-suit-having-breakfast-kitchen-side-view.webp'
              }
            />
            <label
              htmlFor={props.banner ? 'edit-banner-image' : 'add-banner-image'}
              className={classes.fileUpload}>
              {t('UploadAnImage')}
            </label>
            <input
              className={classes.file}
              id={props.banner ? 'edit-banner-image' : 'add-banner-image'}
              type="file"
              accept="image/*"
              onChange={event => {
                selectImage(event, 'imgMenu')
              }}
            />
          </Box>

          {loading || fileLoading ? t('Loading') : null}
          <Box>
            <Button
              className={globalClasses.button}
              disabled={loading || fileLoading}
              onClick={async e => {
                e.preventDefault()
                console.log('onSubmitValidaiton => ', onSubmitValidaiton())
                if(onSubmitValidaiton()){
                  const inputData = {
                    title: data.title,
                    description: data.description,
                    action: data.action,
                    screen: data.screen,
                    file: await uploadImageToCloudinary(),
                    parameters: JSON.stringify([{
                      key: 'restaurant',
                      value: data.restaurantId
                    }])
                  }
                  console.log('onSubmitValidaiton inputData => ', inputData)
                  mutate({
                    variables: {
                      bannerInput: {
                        _id: props.banner ? props.banner._id : '',
                        ...inputData
                      }
                    }
                  })
                }
              }}>
              {t('Save')}
            </Button>
          </Box>
        </form>
        <Box mt={2}>
          {success && (
            <Alert
              className={globalClasses.alertSuccess}
              variant="filled"
              severity="success">
              {success}
            </Alert>
          )}
          {mainError && (
            <Alert
              className={globalClasses.alertError}
              variant="filled"
              severity="error">
              {mainError}
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default withTranslation()(Banner)
